import React, { useState } from "react";
import translations from "../../translations/he.json";
import { navigate, Link } from "gatsby";
import ShortUniqueId from "short-unique-id";
import Plots from "./plots";
import Modal from "./modal";
import { useSelector, useDispatch } from "react-redux";
import { compunds_restrictions } from "./compund-restrictions";
import InfoIcon from "../../images/info.svg";

const uqid = new ShortUniqueId({ length: 10 });

const Stage3 = ({ base }) => {
  const state = useSelector((s) => s.calculator);

  return (
    <>
      {state.compoundId && <Stage3Inner base={base} />}
      {!state.compoundId && (
        <div>
          <Link to="/הרובע-הפתוח/מחשבון-פלוס">
            {translations["Please select compound"]}
          </Link>
        </div>
      )}
    </>
  );
};

const Stage3Inner = ({ base }) => {
  const [show, setShow] = useState(false);
  const state = useSelector((s) => s.calculator);
  const dispatch = useDispatch();

  const compoundMapUrl = `/calculator-images/plots/${state.compoundId}.png`;

  const keys = state.plots.map((p) => `${p.block} ${p.plot}`);
  const rows = state.compound.plots.filter((e) => {
    const a = `${e.block} ${e.plot}`;
    return keys.includes(a);
  });

  //console.log(rows)

  const totals = { area: 0, rights: 0, left: 0 };
  rows.map((r) => {
    totals.area += parseFloat(r.area);
    totals.rights += parseFloat(r.left);
    return null;
  });
  totals.left = totals.rights;

  //zconsole.log(totals)

  const yeods0 = state.yeodim.map((e) => ({
    id: e.id,
    label: e.label,
    main: e.main,
    value: 0,
    total: 0,
  }));

  //console.log('yeods0' , yeods0)
  //console.log('rows' , rows)

  rows.forEach((p) => {
    p.yehodim.forEach((e) => {
      yeods0.forEach((x) => {
        if (x.id === e.yehod) {
          x.value += e.left;
          x.total += e.total;
        }
      });
    });
  });

  const yeods = yeods0.map((e) => ({ ...e, value: Math.floor(e.value) }));

  const yeodsMap = {};
  yeods.forEach((e) => {
    yeodsMap[e.id] = { ...e };
  });

  //console.log('yeodsMap', yeodsMap)

  const M = {};
  state.compound.yehodim.forEach((e) => {
    M[e.yeod] = { ...e };
  });

  const M2 = {};
  state.compound.rights2.forEach((e) => {
    M2[e.yeod] = { ...e };
  });

  // state.usage
  // state.open
  const data = {
    close: {
      optionsAll: [],
      optionsLeft: [],
      selected: [],
      items: [],
      add: true,
    },
    open: {
      optionsAll: [],
      optionsLeft: [],
      selected: [],
      items: [],
      add: true,
    },
  };

  //const focus = [...state.usage, ...state.open].filter(e => e.yeod == '')
  //console.log('focus', focus)

  //console.log(state.compound.rights2)
  //console.log(state.compound.rights.total)

  const ids = state.compound.rights2
    .filter((e) => e.yeod !== "")
    .filter((e) => e.value > 0)
    .map((e) => e.yeod);
  //console.log(ids)

  //console.log('state.usage', state.usage)

  data.close.selected = state.usage.map((s) => s.yeod).filter((s) => s !== "");
  data.close.optionsAll = yeods
    .filter((e) => e.main === "1")
    .filter((e) => ids.includes(e.id))
    .filter((e) => M[e.id].diff > 0);
  data.close.optionsLeft = data.close.optionsAll.filter(
    (e) => !data.close.selected.includes(e.id)
  );

  //console.log( 'yeods', yeods)
  //console.log( 'yeodsMap', yeodsMap)
  //console.log( 'optionsAll', data.close.optionsAll)

  data.close.items = state.usage.map((u, index, all) => ({
    value: u,
    max: 0,
    freeze: index + 1 < all.length ? true : false,
    options: [],
  }));

  const ut = { sum: totals.rights, labels: [] };

  const megurim = {
    yeodim: ["150", "600", "10"],
    limit: totals.rights * 0.3,
    done: 0,
  };

  const megurimNew = megurim.yeodim.map((yeod) => {
    return yeodsMap[yeod].value;
  });

  const megurimNewMax = Math.max(...megurimNew);
  //console.log(megurimNew , megurimNewMax )

  // options
  data.close.items = data.close.items.map((u, index, all) => {
    const item0 = {
      ...u,
      options: data.close.optionsAll.filter(
        (e) => !ut.labels.includes(e.label)
      ),
    };
    ut.labels.push(u.value.label);
    return item0;
  });

  //console.log(yeodsMap)
  //console.log("compunds_restrictions", compunds_restrictions);
  //console.log("state.compoundId", state.compoundId);
  const compund_restrictions = compunds_restrictions.find(
    (res) => res.compound === state.compoundId
  );
  //console.log("compund_restrictions", compund_restrictions);
  const compund_restrictions_ids = compund_restrictions.yeodim.map(
    (res) => res.id
  );
  const compund_restrictions_amount = parseFloat(compund_restrictions.amount);

  //console.log(totals);

  //console.log(data.close.optionsAll);
  //console.log(compund_restrictions);
  //console.log(compund_restrictions_ids);

  const ItemsForSomeDetails = data.close.optionsAll.filter(
    (k) => !compund_restrictions_ids.includes(k.id)
  );
  const ItemsForSomeDetails2 = {
    labels: compund_restrictions.yeodim.map((k) => k.label),
    total:  parseFloat(compund_restrictions.amount) * totals.rights,
  };

  let miztaber = 0;
  //claculate max for close items
  data.close.items = data.close.items.map((u, index, all) => {
    //console.log(u);
    //console.log(ut)
    const a = ut.sum;
    ut.sum -= u.value.area;

    //console.log('miztaber' , miztaber)

    let max = 0;

    if (u.value.yeod) {
      const YeodMithamMax =
        M2[u.value.yeod].value * state.compound.rights.total;

      const buildingArea = yeodsMap[parseInt(u.value.yeod)].value;
      //console.log("buildingArea", buildingArea);

      max = Math.min(buildingArea, a, YeodMithamMax);

      // if yeod is 10, 150 or 600 then do anther check
      if (megurim.yeodim.includes(u.value.yeod)) {
        //const mtm = megurimNewMax

        max = Math.min(max, megurimNewMax - megurim.done);

        //max = Math.min(buildingArea,mtm)
        //console.log(megurimNewMax - megurim.done)
        //   //console.log('M2' , M2, M2[u.value.yeod].value , totals.rights *  M2[u.value.yeod].value )

        //   //console.log('max(', max, megurim.limit, megurim.done)
        //   max = Math.min(max, ( totals.rights *  M2[u.value.yeod].value - megurim.done))
        megurim.done += parseInt(u.value.area);
      }

      // check if relevant . if yeod is in the list of compund_restrictions yeodim

      if (compund_restrictions_ids.includes(u.value.yeod) && !u.freeze) {
        //console.log("ut", ut);
        let max0 = parseInt(
          totals.rights * compund_restrictions_amount - miztaber
        );

        //console.log(state.compound.rights.total)
        //console.log(compund_restrictions_amount)
        //console.log(max0)
        max = Math.min(max, max0);
      }

      //console.log(r)
    }

    if (compund_restrictions_ids.includes(u.value.yeod)) {
      miztaber += parseInt(u.value.area);
    }

    return { ...u, max: max };
  });

  //console.log('data.close.items', data.close.items )
  //console.log('megurim=', megurim )

  data.open.selected = state.open.map((s) => s.yeod).filter((s) => s !== "");
  data.open.optionsAll = yeods.filter((e) => M[e.id].diff2 > 0);
  data.open.optionsLeft = data.open.optionsAll.filter(
    (e) => !data.open.selected.includes(e.id)
  );

  data.open.items = state.open.map((u, index, all) => ({
    value: u,
    max: 0,
    freeze: index + 1 < all.length ? true : false,
    options: [],
  }));

  // options
  ut.labels = [];
  data.open.items = data.open.items.map((u, index, all) => {
    const item0 = {
      ...u,
      options: data.open.optionsAll.filter((e) => !ut.labels.includes(e.label)),
    };
    ut.labels.push(u.value.label);
    return item0;
  });

  const runningItems = [...data.close.items];
  const OpenMax = M2[""].value * state.compound.rights.total;

  //console.log('OpenMax=',OpenMax)

  const areaSelected = {
    sum: 0 /*data.close.items
          .map( u1 => ( parseInt( u1.value.area)))
          .reduce((partialSum, a) => partialSum + a, 0)*/,
  };

  //console.log('AreaSelected=',areaSelected)

  // max for open items
  data.open.items = data.open.items.map((u, index, all) => {
    //console.log(u)
    const a = ut.sum;
    ut.sum -= u.value.area;

    //console.log(a, ut.sum)

    const runningOpenMAx = OpenMax - areaSelected.sum;
    //console.log('runningOpenMAx', runningOpenMAx)

    areaSelected.sum += parseInt(u.value.area);
    //console.log('areaSelected', areaSelected)

    let max = 0;
    if (u.value.yeod) {
      //console.log(M)
      //console.log(u.value.yeod)
      //console.log(M[u.value.yeod].diff2)
      //console.log( 'a',a)
      //console.log( 'runningOpenMAx',runningOpenMAx)

      max = Math.min(M[u.value.yeod].diff2, a, runningOpenMAx);

      //console.log('max1', max)

      const rele = data.close.items
        .filter((u1) => u1.value.yeod === u.value.yeod)
        .map((u1) => parseInt(u1.value.area))
        .reduce((partialSum, a) => parseInt(partialSum) + a, 0);
      //console.log('rele', rele)
      //console.log(M2)
      let YeodMithamMax = 0;
      if (rele > 0) {
        //console.log('state.compound.rights' , state.compound.rights)
        //console.log('M2[u.value.yeod].value' , M2[u.value.yeod].value)
        //console.log('M2' , M2)
        //console.log('M2[""]' , M2[''].value)

        //YeodMithamMax = M2[u.value.yeod].value * state.compound.rights.total
        YeodMithamMax = M2[""].value * state.compound.rights.total;
        //console.log('YeodMithamMax' , YeodMithamMax)

        const diff3 = YeodMithamMax; // - rele
        //console.log('diff3' , diff3 )
        max = Math.min(max, diff3);

        const buildingArea = yeodsMap[parseInt(u.value.yeod)].value;
        max = Math.min(max, buildingArea - rele);
      }
      //const YeodOpenAlreadyUsegedOrWhatever =
      //console.log('max2', max)

      if (megurim.yeodim.includes(u.value.yeod)) {
        //   max = Math.min(max, (megurim.limit - megurim.done))
        //   megurim.done += parseInt(u.value.area)

        //console.log(u.freeze)
        //console.log([...data.close.items, ...data.open.items.filter(uu => uu.freeze)])

        const ar = runningItems
          .filter((u2) => megurim.yeodim.includes(u2.value.yeod))
          .map((u2) => u2.value.area)
          .reduce((partialSum, a) => partialSum + parseInt(a), 0);

        //console.log('ar', ar )
        //console.log('megurimNewMax', megurimNewMax )
        runningItems.push(u);

        const Zobi = megurimNewMax - ar;
        //console.log('max=', max)
        //console.log('zobi=', Zobi)
        max = Math.min(max, Zobi);
      }

      if (compund_restrictions_ids.includes(u.value.yeod) /*&& ! u.freeze*/) {
        //console.log('ut' , ut)
        let max0 = parseInt(
          totals.rights * compund_restrictions_amount - miztaber
        );

        //console.log(state.compound.rights.total)
        //console.log(compund_restrictions_amount)
        //console.log(max0)
        max = Math.min(max, max0);
      }
    }

    if (compund_restrictions_ids.includes(u.value.yeod)) {
      miztaber += parseInt(u.value.area);
    }

    return { ...u, max: max };
  });

  //console.log('data.open.items', data.open.items )
  //console.log('megurim=', megurim )

  totals.left = ut.sum;

  data.close.items = data.close.items.map((e) => ({
    ...e,
    freeze: data.open.items.length > 0 ? true : e.freeze,
  }));

  // add more buttons logic
  const b0 = data.close.items.map((e) => e.value.yeod).filter((e) => e === "");
  data.close.add =
    data.close.optionsLeft.length > 0 &&
    data.open.items.length === 0 &&
    b0.length === 0
      ? true
      : false;
  data.open.add =
    data.open.optionsLeft.length > 0 && b0.length === 0 ? true : false;

  const gotoPage4 = () => {
    navigate(base + "?stage=4");
  };

  const addUsage = () => {
    dispatch({
      type: "calculator/add-usage",
      payload: {
        id: uqid(),
        yeod: "",
        label: "",
        area: 0,
        cost: 0,
        revenue: 0,
      },
    });
  };

  const addOpenUsage = () => {
    dispatch({
      type: "calculator/add-open-usage",
      payload: {
        id: uqid(),
        yeod: "",
        label: "",
        area: 0,
        cost: 0,
        revenue: 0,
      },
    });
  };

  return (
    <div className="flex flex-col-reverse lg:flex-row">
      <div className="lg:w-1/2 space-y-12">
        <div>
          <div>
            <span className="text-2xl font-bold text-blue">
              {translations["selected plots"]}
            </span>
          </div>
          <Plots />
        </div>

        <div className="">
          <span className="text-2xl font-bold text-blue">
            {translations["The area of the selected plots"]}
          </span>
          {"  "} {totals.area.toLocaleString()} {translations["Square meter"]}
        </div>

        <div className="">
          <div>
            <span className="text-2xl font-bold text-blue">
              {
                translations[
                  "Maximum construction rights by virtue of the policy plan"
                ]
              }
            </span>
          </div>
          <div>
            {"  "} {totals.rights.toLocaleString()}{" "}
            {translations["Square meter"]}
          </div>
        </div>

        <SomeDetails
          title={translations["stage3.plots-selected-build.header"]}
          footer={translations["stage3.plots-selected-build.footer"]}
          items1={ItemsForSomeDetails}
          items2={ItemsForSomeDetails2}
        />

        <div className="">
          <div>
            <span className="text-2xl font-bold text-blue">
              {translations["Distribution of uses for the program"]}
            </span>
          </div>
          <div>
            <span className="text-lg font-bold ">
              {translations["Distribution of uses for the program - sub title"]}
            </span>
          </div>
          <div>
            <span className="text-base ">
              {translations["Distribution of uses for the program - text"]}
            </span>
          </div>
        </div>

        <div className="space-y-12">
          {data.close.items.map((e, index) => (
            <UsageInput
              options={e.options}
              key={index}
              value={e.value}
              brand="usage"
              max={e.max}
              freeze={e.freeze}
            />
          ))}

          <div className="flex flex-row space-x-4 space-x-reverse  items-center">
            <button
              onClick={addUsage}
              className="w-8 h-8 text-white border  rounded-full bg-yellow flex  justify-center  items-center font-bold"
              disabled={!data.close.add}
            >
              +
            </button>
            <div>{translations["Additional service"]}</div>
          </div>

          <div className="border-b border-yellow border-b-2 "></div>

          <div className="text-blue font-bold text-2xl flex lg:flex-row items-center space-x-2 space-x-reverse ">
            <div className="">
              {translations["Choosing additional uses for the program"]}
            </div>
            <div className="flex items-center">
              <button
                className="rounded-full bg-blue text-white text-bold w-5 h-5 text-xs  "
                onClick={() => setShow(true)}
              >
                ?
              </button>
            </div>
          </div>

          {data.open.items.map((e, index) => (
            <UsageInput
              options={e.options}
              key={index}
              value={e.value}
              brand="open"
              max={e.max}
              freeze={e.freeze}
            />
          ))}

          <div className="flex flex-row space-x-4 space-x-reverse items-center">
            <button
              onClick={addOpenUsage}
              className="w-8 h-8 text-white border  rounded-full bg-yellow flex  justify-center  items-center font-bold"
              //disabled={data.open.optionsLeft.length > 0 /* and no open items */ ? false : true}
              disabled={!data.open.add}
            >
              +
            </button>
            <div>{translations["Added another open service"]}</div>
          </div>
        </div>

        <div className="">
          <UsageTotals />
        </div>

        <button
          className="w-full border p-3  bg-navy text-white hover:bg-blue my-12 font-bold"
          onClick={gotoPage4}
        >
          {translations["Viewing the program data summary"]}
        </button>
        <div></div>
      </div>

      <div className="lg:w-1/2">
        <img src={compoundMapUrl} alt="plot" />
      </div>

      <Modal
        show={show}
        closeModal={() => setShow(false)}
        text={translations["possible uses.open.modal.text"]}
        title={translations["possible uses.open.modal.title"]}
      />
    </div>
  );
};

const UsageInput = ({ options, value, brand, max, freeze }) => {
  const dispatch = useDispatch();

  const onChangeUsage = (e) => {
    const p2 = options.find((v) => v.id === e.target.value);

    //console.log('p2', p2)

    dispatch({
      type: "calculator/change-" + brand,
      payload: {
        id: value.id,
        value: {
          yeod: e.target.value,
          label: p2?.label,
          area: 0,
          cost: 0,
          revenue: 0,
        },
      },
    });
  };

  const onSlide = (e) => {
    dispatch({
      type: "calculator/change-" + brand,
      payload: { id: value.id, value: { area: e.target.value } },
    });
  };

  const onChangeCost = (e) => {
    dispatch({
      type: "calculator/change-" + brand,
      payload: { id: value.id, value: { cost: e.target.value } },
    });
  };

  const onChangeRevenue = (e) => {
    dispatch({
      type: "calculator/change-" + brand,
      payload: { id: value.id, value: { revenue: e.target.value } },
    });
  };

  const remove = (e) => {
    dispatch({
      type: "calculator/remove-" + brand,
      payload: { id: value.id },
    });
  };

  const active = value.yeod !== "";
  //console.log('value.yeod=', value.yeod);

  return (
    <div className="flex flex-col lg:flex-row  my-6">
      <div className="border  shadow-lg ">
        <div className="flex flex-col  lg:flex-row ">
          <div className="lg:w-96 p-3">
            <div className="mb-8">
              <select
                onChange={onChangeUsage}
                value={value.yeod}
                className="p-3 border "
                disabled={freeze}
              >
                {value.yeod === "" && (
                  <option key={0} value={0}>
                    בחר יעוד
                  </option>
                )}
                {options.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <div className="bg-[#ECB759] max-w-fit p-1" children="">
                {parseInt(value.area).toLocaleString()}
              </div>
              <input
                className=" w-full appearance-none -accent-orange-500"
                type="range"
                min={0}
                max={max}
                value={value.area}
                step={10}
                onChange={onSlide}
                disabled={!active || freeze}
              />
            </div>
            <div className="flex  justify-between">
              <div>0</div>
              <div>{max.toLocaleString()} </div>
            </div>
            <div className="flex justify-end">
              <div>{translations["Maximum rights in the program"]}</div>
            </div>
          </div>
          <div className="w-full lg:w-48 lg:bg-[#A9DFF3] p-3 lg:space-y-4">
            <div className="flex flex-row lg:flex-col justify-between">
              <div>{translations["Expected cost per square meter"]}</div>
              <div>
                <input
                  type="number"
                  step="1"
                  className="bg-white border drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] w-full p-1"
                  onChange={onChangeCost}
                  value={value.cost}
                  disabled={!active}
                />
              </div>
            </div>
            <div className="flex flex-row lg:flex-col justify-between ">
              <div>{translations["Expected income per square meter"]}</div>
              <div>
                <input
                  type="number"
                  step="1"
                  className="bg-white border drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] w-full p-1"
                  onChange={onChangeRevenue}
                  value={value.revenue}
                  disabled={!active}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-end lg:mr-12 mr-2 mb-2">
        <button
          id={value.id}
          onClick={remove}
          className="w-8 h-8 rounded-full border bg-yellow text-white font-bold"
        >
          -
        </button>
      </div>
    </div>
  );
};

const UsageTotals = () => {
  const state = useSelector((s) => s.calculator);

  const summary = state.summary;

  return (
    <div className=" flex flex-col  lg:flex-row p-3">
      <div className="lg:w-1/2 p-3 space-y-3">
        <SplitRow
          label={translations["Realized building rights (m2)"]}
          value={summary.area.toLocaleString()}
        />
        <SplitRow
          label={translations["Remaining building rights (m2)"]}
          value={summary.left.toLocaleString()}
        />
      </div>

      <div className="lg:w-1/2 bg-black text-white p-3">
        <div className="flex flex-row text-center">
          <div className="w-1/2">{translations["total revenue"]}</div>
          <div className="w-1/2">{translations["total costs"]}</div>
        </div>

        <div className="flex flex-row">
          <div className="w-1/2 bg-white text-black mx-2 my-1 p-1">
            {summary.income.toLocaleString()}{" "}
          </div>
          <div className="w-1/2 bg-white text-black mx-2 my-1 p-1">
            {summary.cost.toLocaleString()}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

const SplitRow = ({ label, value }) => {
  return (
    <div className="flex flex-row">
      <div className="font-bold w-64">{label}:</div>
      <div>{value}</div>
    </div>
  );
};

const SomeDetails = ({ title, footer, items1, items2 }) => {
  const rows = items1.map((k, index) => (
    <div key={index} className="space-x-1.5 space-x-reverse">
      <span>{"בשימוש"}</span>
      <span className="font-black">{k.label}</span>
      <span>{"עד"}</span>
      <span className="underline">
        {parseInt(k.total).toLocaleString()} {'מ"ר'}.
      </span>
    </div>
  ));

  const label = items2.labels.join(", ");

  return (
    <div>
      <div className="text-lg text-black -mr-7 space-x-2 space-x-reverse">
        <img src={InfoIcon} alt="info" className="inline" />
        <span className="font-black text-lg">{title}</span>
      </div>
      <div className="text-lg py-5 space-y-1.5">
        {rows}
        <div className="space-x-2 space-x-reverse">
          <span>{'בשילוב השימושים של'}</span>
          <span className="font-black">{label}</span>
          <span>{'עד'}</span>
          <span className="underline">
        {parseInt(items2.total).toLocaleString()} {'מ"ר'}.
      </span>

         
        </div>
      </div>
      <div className="text-base">{footer}</div>
    </div>
  );
};
export default Stage3;
