import React from "react"
import translations from "../../translations/he.json"
//import { useCalculator } from '../../calculator-context'
import { useSelector/* , useDispatch */ } from 'react-redux'

const Usages = () => {

  //const { state } = useCalculator()
  const state = useSelector(s => s.calculator)
  //console.log(state.plots)


  const rows = [
    ...state.usage,
    ...state.open.map(e => ({ ...e, label: e.label + ' -פתוח' }))
  ].filter(e => e.yeod)

  //console.log(rows)

  return (

    <div className="p-2">

      <div className=" p-2 my-3 grid grid-cols-4 lg:w-3/4 font-bold" >
        <div></div>
        <div className="p-1">{translations['planned square meter']}</div>
        <div className="p-1">{translations['Cost per square meter']}</div>
        <div className="p-1">{translations['Income per square meter']}</div>
      </div>


      {rows.map((m, index) => (
        <div className="border border-black p-2 my-3 grid grid-cols-4 lg:w-3/4" key={index}>
          <div className="font-bold p-1">{m.label}</div>
          <div >{parseInt(m.area).toLocaleString()}</div>
          <div>{parseFloat(m.cost).toLocaleString()}</div>
          <div>{parseFloat(m.revenue).toLocaleString()}</div>
        </div>
      ))}
    </div>
  )
}


export default Usages