import React from "react"

const Modal = ({ show, closeModal, title, text }) => {

  return (

    <div className={"modal opacity-0  fixed w-full h-full top-0 left-0 flex items-center justify-center " + (show ? ' block opacity-100' : ' hidden opacity-0')} style={{ zIndex: '10' }}>
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50" onClick={closeModal} onKeyDown={closeModal} role="presentation"></div>

      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg  overflow-y-auto" style={{ zIndex: '150' }}>
        <div className="modal-content py-4 px-6" >

          <div className="flex justify-between items-center pb-3">
            <button className="modal-close cursor-pointer z-50" onClick={closeModal}>
              <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </button>
          </div>

          {title && <h2 className="text-2xl font-bold text-blue my-3">{title}</h2>}
          <div className="text-justify">{text}</div>


        </div>
      </div>
    </div>
  )
}




export default Modal
