import React from "react"
import translations from "../../translations/he.json"
import { useSelector } from 'react-redux'
import { flipBrackets } from "../../util/common"

const Profit = ({ pdf = false }) => {

  const state = useSelector(s => s.calculator)
  const summary = state.summary

  const profit = summary.profit
  const cost = Math.round(summary.cost / 1000)
  const income = Math.round(summary.income / 1000)

  return (

    <div className="flex flex-col lg:flex-row shadow-lg my-8">

      {!pdf &&
        <div className=" border p-2 border space-y-2 lg:w-3/4">
          <Row label={translations['Prescribed rights']} value={summary.area.toLocaleString()} />
          <Row label={translations['Total rights remaining']} value={summary.left.toLocaleString()} />
          <Row label={translations['Total expected cost (in thousands of NIS)']} value={cost.toLocaleString()} />
          <Row label={translations['Total expected income (in thousands of NIS)']} value={income.toLocaleString()} />
        </div>
      }

      {pdf &&
        <div className=" border p-2 border space-y-2 lg:w-3/4">
          <Row label={flipBrackets(translations['Prescribed rights'])} value={summary.area.toLocaleString()} />
          <Row label={flipBrackets(translations['Total rights remaining'])} value={summary.left.toLocaleString()} />
          <Row label={flipBrackets(translations['Total expected cost (in thousands of NIS)'])} value={cost.toLocaleString()} />
          <Row label={flipBrackets(translations['Total expected income (in thousands of NIS)'])} value={income.toLocaleString()} />
        </div>
      }

      <div className="lg:w-1/4 bg-[#414042] text-white p-8 text-center">
        <div>{translations['Expected gross profit']}</div>
        <div>{profit.toFixed(1)} %</div>
      </div>

    </div>

  )
}


const Row = ({ label, value }) => {
  return (
    <div className="flex flex-row">
      <div className="font-bold w-64">{label}</div>
      <div>{value}</div>
    </div>
  )
}

export default Profit