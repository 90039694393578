import React, { useState } from "react"
import { useSelector } from 'react-redux'
import translations from "../../translations/he.json"
import { PieChart } from 'react-minimal-pie-chart';
import Modal from "./modal"

const CompoundUsageTable = () => {

  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)

  //const { state } = useCalculator()
  const state = useSelector(s => s.calculator)
  const data = state.compound.rights2.filter(e => e.value > 0).map(e => ({ label: e.label, value: 100 * parseFloat(e.value) }))

  const colors = [
    '#B99FE9',
    '#CDCDCF',
    '#E07092',
    '#9DADF3',
    '#ECB759',
  ]

  const pieData = data.map((v, index) => ({
    value: v.value,
    title: v.value + '%',
    color: colors[index],
  }))

  const s = "לחצו כאן להדגמה."
  const r = <button onClick={() => setShow(true)} className="font-bold text-blue inline text-lg">{s}</button>
  const t = translations['possible uses - text'].replace(s, '')
  //console.log(data)
  return (
    <>
      <div className="flex flex-col lg:flex-row r">
        <div className=" lg:w-1/3 p-3  ">

          {data.map((e, index) => (
            <div className="flex flex-nowrap items-baseline" key={index}>
              <div className="w-3 h-3 rounded-full ml-3" style={{ backgroundColor: colors[index] }}></div>
              <div className="flex flex-nowrap items-baseline">{e.label}
                <span
                  className="text-xs mr-1">
                  {e.label === 'פתוח' &&
                    <button className="border rounded-full bg-blue text-white text-bold w-5 h-5 text-xs" onClick={() => setShow2(true)}>?</button>
                  }
                </span>
              </div>
              {/* <div>{e.value} %</div> */}


            </div>
          ))}


        </div>

        <div className="lg:w-2/3 space-y-8 p-3">
          <div className='w-48'>
            <PieChart
              data={pieData}
              label={({ dataEntry }) => dataEntry.value + "%"}
              labelStyle={{ fontSize: "8px", fill: "white" }}
            />


          </div>

          <div>
            <div className="text-justify">{t}</div>{r}</div>

        </div>
      </div>

      <Modal show={show} closeModal={() => setShow(false)} text={translations['possible uses - modal']} />
      <Modal show={show2} closeModal={() => setShow2(false)} text={translations['possible uses.open.modal.text']} title={translations['possible uses.open.modal.title']} />


    </>
  )
}


export default CompoundUsageTable
