


const breakOnPeriod = (s) => {


  if (s === 'undefined' || s === null) return s

  const parts = s.trim().split('.')
  const r = parts.join('.<br/>')

  if (r.slice(-5) === '<br/>') {
    return r.split(0, -5)
  } else {
    return r
  }

}


const pipesToSpan = (text) => {
  const s = /\|(.*?)\|/
  const parts = text.split(s)
  let t = ''
  parts.forEach((p, i) => {
    t += i % 2 === 0 ? p : '<span class="text-blue">' + p + '</span>'
  });

  return t
}


const flipBrackets = (s) => {
  const a = s.replace('(', '<|>')
  const b = a.replace(")", "(")
  const c = b.replace('<|>', ')')
  return c
}


export { breakOnPeriod, pipesToSpan, flipBrackets }