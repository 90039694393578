import React from "react"
import { Link } from "gatsby"
import translations from "../../translations/he.json"
import Stage1 from "../../components/calculator/stage1";
import Stage2 from "../../components/calculator/stage2";
import Stage3 from "../../components/calculator/stage3";
import Stage4 from "../../components/calculator/stage4";
import Layout from "../../components/layout"
import SperatorMenu from "../../images/seperator.svg"
import { useSelector/* , useDispatch */ } from 'react-redux'
import UserInteraction from "../../components/user-interaction";
import Flag from "../../images/goal-flag.svg"
import Seo from "../../components/Seo"

const CalculatorPage = ({ location, ...props }) => {

  const user = useSelector(state => state.user)
  //const calculator = useSelector(state => state.calculator)
  //const dispatch = useDispatch()

  const stage = location.search.replace('?stage=', '')

  const active = stage === '' ? 1 : parseInt(stage)
  const preTitle = translations['Calculator Plus']

  let seo = { title: preTitle + ': כניסת משתמשים' }

  if (user.isLoggedIn) {
    switch (stage) {
      case '2':
        seo.title = preTitle + ': ' + translations['Select Plots']
        break;
      case '3':
        seo.title = preTitle + ': ' + translations['Build Usages']
        break;
      case '4':
        seo.title = preTitle + ': ' + translations['Plan Summary']
        break
      default:
        seo.title = preTitle + ': ' + translations['Choose Compound']
    }
  }

  const menu = [
    { label: "1. " + translations['Choose Compound'], to: location.pathname /* + '?stage=1' */, index: 1, active: stage === '', done: true },
    { label: "2. " + translations['Select Plots'], to: location.pathname + '?stage=2', index: 2, active: stage === '2', done: stage === "3" || stage === "4" },
    { label: "3. " + translations['Build Usages'], to: location.pathname + '?stage=3', index: 3, active: stage === '3', done: stage === "4" },
    { label: /* "4. " + */ translations['Plan Summary'], to: location.pathname + '?stage=4', index: 4, active: stage === '4', done: false },
  ]


  return (
    <Layout>
      <div className="container px-0 lg:px-6 mx-auto mt-[90px] lg:mt-[126px] lg:pt-12 pt-4 mb-12 ">

        {!user.isLoggedIn &&
          <>
          <Seo {...seo} />
          <PleaseLoginOrRegister />
        </>
        }
        {user.isLoggedIn &&
          <>
            <Seo {...seo} />
            <menu className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-1  lg:space-x-reverse mb-12 mt-6 lg:items-center hidden lg:flex">
              {menu.map((m, index) => (
                <React.Fragment key={index}>
                  <MItem to={m.to} label={m.label} active={m.active} done={m.done} index={m.index} />
                  <div className="last:hidden hidden lg:block"><img src={SperatorMenu} alt="seperator" /></div>
                </React.Fragment>
              ))}
            </menu>

            <div className="lg:hidden">
              <MobileMenu items={menu} active={active} pathname={location.pathname} />
            </div>

            <div className="px-4 lg:px-0">
              {/* stage === '1' || */ stage === '' && <Stage1 base={location.pathname} />}
              {stage === '2' &&  <Stage2 base={location.pathname} />}
              {stage === '3' && <Stage3 base={location.pathname} />}
              {stage === '4' && <Stage4 base={location.pathname} />}
            </div>
          </>
        }
      </div>
    </Layout>
  )
}


const MItem = ({ to, label, index, active = false, done = false, disabled = false }) => {


  //const c =
  return (

    <div className="" >
      <Link
        className={"p-3 border text-white m-3 lg:block " + (active ? ' bg-blue  ' : (done ? ' bg-[#A9DFF3] ' : ' bg-[#CDCDCD] '))}
        to={to}
      >
        {index === 4 && <img src={Flag} alt="flag" className="inline ml-2" />}
        {label}
      </Link>
    </div>
  )

}


const MobileMenu = ({ items, active, pathname }) => {

  // active = 1 the 1 and 2
  /* active = 2 then 1 2 3
  3  234
  4 34  */

  const selected = items.find(e => e.index === active)


  const next = selected.index === 4 ? false : pathname + '?stage=' + (selected.index + 1)
  const prev = selected.index === 1 ? false : (selected.index === 2 ? pathname : pathname + '?stage=' + (selected.index - 1))

  //console.log(selected, next, prev)

  return (
    <div className="flex flex-row justify-between my-6 items-center ">


      <div>{prev &&
        <Link to={prev} >
          <div className="w-6 h-10 bg-[#A9DFF3]"></div>
        </Link>
      }
      </div>
      {prev && <div className=""><img src={SperatorMenu} alt="seperator" /></div>}
      {!prev && <div className=""></div>}
      <div>
        <MItem {...selected} />
      </div>
      {next && <div className=""><img src={SperatorMenu} alt="seperator" /></div>}
      {!next && <div className=""></div>}
      <div>{next &&
        <Link to={next}>
          <div className="w-6 h-10 bg-[#CDCDCD]"></div>
        </Link>
      }</div>

    </div>
  )
}


const PleaseLoginOrRegister = () => {

  const destination = () => {
    localStorage.setItem('destination', '/הרובע-הפתוח/מחשבון-פלוס/')
  }

  return (
    <UserInteraction
      title={'הרשמת משתמשים'}
      className="lg:bg-[url(/backgrounds/pattern3.svg)]"
    >
      <div className="text-center space-y-6 ">
        <div className="text-lg ">הרשמה למערכת תאפשר לך גישה למחשבון הזכויות</div>
        <div className="flex flex-row space-x-8 space-x-reverse">
          <Link to={'/register'} onClick={destination} className="block p-3 bg-navy text-lg text-white w-fit w-64 text-center" >הרשמה</Link>
          <Link to={'/'} /* onClick={destination} */ className="block p-3 border border-navy text-primary w-fit text-lg w-64 text-center" >לא כרגע</Link>
        </div>
        <div>
          יש לי כבר משתמש -
          <Link to={'/login'} onClick={destination} className=" p-3 text-blue w-fit" >אני רוצה להתחבר</Link>
        </div>
      </div>
    </UserInteraction>
  )
  /* return (
    <div className="space-y-3 my-8 lg:bg-[url(/backgrounds/pattern3.svg)] bg-no-repeat text-primary container lg:min-h-[856px] ">
      <div className="lg:mr-24 lg:w-[600px] py-6 lg:py-24 px-4 text-center space-y-6 ">
        <h2 className="text-[34px] font-bold text-blue">הרשמת משתמשים</h2>
        <div className="text-lg ">הרשמה למערכת תאפשר לך גישה למחשבון הזכויות</div>
        <div className="flex flex-row space-x-8 space-x-reverse">
          <Link to={'/register'} className="block p-3 bg-navy text-lg text-white w-fit w-64 text-center" >הרשמה</Link>
          <Link to={'/'} className="block p-3 border border-navy text-primary w-fit text-lg w-64 text-center" >לא כרגע</Link>
        </div>
        <div>
          יש לי כבר משתמש -
          <Link to={'/login'} className=" p-3 text-blue w-fit" >אני רוצה להתחבר</Link>
        </div>
      </div>
    </div>
  ) */
}

export default CalculatorPage
