import React, { useState } from "react";
import translations from "../../translations/he.json";
import { navigate } from "gatsby";
import { useSelector, useDispatch } from "react-redux";
import ShortUniqueId from "short-unique-id";

const uqid = new ShortUniqueId({ length: 10 });

const Stage1 = ({ base }) => {
  const [selected, setSelected] = useState("");

  const state = useSelector((state) => state.calculator);
  const dispatch = useDispatch();

  const selectCompound2 = (e) => {
    const compoundId = e.target.value;
    //console.log(compoundId)
    //return
    if (compoundId !== "") {
      const compound = state.compounds.find((m) => m.name === compoundId);

      dispatch({
        type: "calculator/reset",
        payload: {
          compoundId: compoundId,
          compound: compound,
          plots: [{ id: uqid(), plot: "", block: "" }],
        },
      });

      navigate(base + "?stage=2");
    } else {
      dispatch({
        type: "calculator/reset",
        payload: { compoundId: false, compound: false, plots: [] },
      });
    }
  };

  const all = state.compounds.map((c) => ({
    name: c.name,
    rights: c.rights,
    usage: c.usage,
    image: c.image,
  }));

  const Letters = [
    { name: "A", top: 10, right: 37 },
    { name: "B", top: 24, right: 20 },
    { name: "C", top: 42, right: 28 },
    { name: "D", top: 66, right: 44 },
    { name: "E", top: 82, right: 58 },
    { name: "F", top: 66, right: 62 },
    { name: "G", top: 34, right: 59 },
    { name: "H", top: 15, right: 52 },
    { name: "I", top: 19, right: 35 },
    { name: "J", top: 27, right: 39 },
    { name: "K", top: 39, right: 39 },
    { name: "L", top: 35, right: 51 },
    { name: "M", top: 52, right: 47 },
    { name: "N", top: 66, right: 54 },
  ];

  const Sections = [
    { name: "A", top: "7.2%", right: "23%" },
    { name: "B", top: "19%", right: "14%" },
    { name: "C", top: "31%", right: "19%" },
    { name: "D", top: "60%", right: "41%" },
    { name: "E", top: "76.8%", right: "52.8%" },
    { name: "F", top: "59.2%", right: "63.4%" },
    { name: "G", top: "23.9%", right: "54.6%" },
    //{ name: 'H', top: 125, right: 385 },
    { name: "I", top: "12.8%", right: "28.4%" },
    { name: "J", top: "24.5%", right: "34.4%" },
    { name: "K", top: "33.8%", right: "35%" },
    { name: "L", top: "29.2%", right: "46%" },
    { name: "M", top: "49%", right: "36.4%" },
    { name: "N", top: "59.4%", right: "47.4%" },
  ];

  const s = Letters.find((a) => a.name === selected);
  const s2 = all.find((a) => a.name === selected);

  //console.log('s2', s2)
  //console.log(calculation.compound)
  return (
    <div className="flex flex-col  lg:flex-row justify-between space-y-6 lg:space-y-0 ">
      <div className="w-full lg:w-auto space-y-4 mb-16 flex flex-col gap-y-4">
        <div className="relative h-6">
          <div className="my-box block ">
            <select
              className="p-2 border"
              onChange={selectCompound2}
              value={state.compoundId}
            >
              <option key="-" value="">
                {translations["Compound"]}
              </option>
              {state.compounds.map((m) => {
                return (
                  <option
                    key={m.name}
                    value={m.name}
                  >{`${translations["Compound"]} ${m.name}`}</option>
                );
              })}
            </select>
          </div>
        </div>

        <SearchWidget base={base} />

        <div className="text-right">
          <a
            href="https://www.gov.il/apps/mapi/parcel_address/parcel_address.html"
            target="__blank"
            className="underline text-blue"
          >
            לחצו לאיתור גוש חלקה לפי כתובת
          </a>
        </div>
      </div>

      <div className="w-full lg:w-auto">
        <div className="relative">
          <img src={"/calculator-images/all-compunds.png"} alt="all compunds" />
          <img
            src="/calculator-images/contour.svg"
            alt=""
            className="absolute hidden lg:block "
            style={{
              top: "47px",
              right: "126px",
            }} /* onMouseOver={() => { console.log('over', 'a-section') }} */
          />
          {Sections.map((e, index) => (
            <img
              src={`/calculator-images/${e.name}-section.svg`}
              alt=""
              className="absolute hidden lg:block "
              style={{ top: e.top, right: e.right, fill: "#ff0000" }}
              key={index}
            />
          ))}

          {Letters.map((e, index) => (
            <div
              key={index}
              className=" absolute"
              style={{ top: `${e.top}%`, right: `${e.right}%` }}
            >
              <button
                className="flex flex-col border border-gray-200 justify-center content-center relative w-6 h-6 lg:w-12 lg:h-12 bg-white text-yellow2 rounded-full block "
                onClick={() => setSelected(e.name)}
                onMouseEnter={() => setSelected(e.name)}
                onMouseLeave={() => setSelected("")}
                //style={{ top: `${e.top}%`, right: `${e.right}%` }}
              >
                <div
                  className={` text-center font-bold text-base lg:text-2xl mx-auto`}
                >
                  {e.name}
                </div>
              </button>
            </div>
          ))}

          <div
            className={
              "absolute hidden lg:block  border lg:w-80 bg-white " +
              (selected !== "" ? " lg:block " : " lg:hidden")
            }
            style={{ top: `${s?.top + 7}%`, right: `${s?.right + 7}%` }}
          >
            <div className="border  p-6">
              <div className="text-base lg:text-xl font-bold">
                {translations["Compound"]} {selected}
                {/* {c.name} */}
              </div>
              <div>
                <div>
                  {translations["Construction rights utilized"]} :
                  {s2?.rights.utilized.toLocaleString()}{" "}
                  {translations["Square meter"]}
                </div>
                <div>
                  {translations["Construction rights left"]} :
                  {s2?.rights.left.toLocaleString()}{" "}
                  {translations["Square meter"]}
                </div>
                <div>{"בהתבסס על תכנית המדיניות"}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden mt-6">
          <div
            className={
              " border lg:w-80 bg-white " +
              (selected !== "" ? " block " : " hidden")
            }
            style={{ top: `${s?.top + 7}%`, right: `${s?.right + 7}%` }}
          >
            <div className="border  p-6">
              <div className="text-base lg:text-xl font-bold">
                {translations["Compound"]} {selected}
                {/* {c.name} */}
              </div>
              <div>
                <div>
                  {translations["Construction rights utilized"]} :
                  {s2?.rights.utilized.toLocaleString()}{" "}
                  {translations["Square meter"]}
                </div>
                <div>
                  {translations["Construction rights left"]} :
                  {s2?.rights.left.toLocaleString()}{" "}
                  {translations["Square meter"]}
                </div>
                <div>{"בהתבסס על תכנית המדיניות"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchWidget = ({  base }) => {

  const state = useSelector((state) => state.calculator);
  const dispatch = useDispatch();

  const compounds = state.compounds

  const flat = (compounds) => {
    const items = [];
    for (const compound of compounds) {
      for (const plot of compound.plots) {
        items.push({
          name: compound.name,
          plot: plot.plot,
          block: plot.block,
        });
      }
    }

    return items;
  };
  const items = flat(compounds);

  const [block, setBlock] = useState(0);
  const [query, setQuery] = useState('');

  const options = [...new Set(items.map((item) => parseInt(item.block)))].sort(
    (a, b) => a - b
  );

  const selectBlock = (e) => {
    setBlock(e.target.value);
  };

  const search = (e) => {
    setQuery(e.target.value);
  };

  const selected = (e) => {
    const parts = e.target.value.split("-");

    const compoundId = parts[0];
    const block = parts[1];
    const plot = parts[2];

    const compound = compounds.find((c) => c.name === compoundId);

    const id = uqid();

    dispatch({
      type: "calculator/reset",
      payload: {
        compoundId: compoundId,
        compound: compound,
        plots: [
          {
            id: id,
            plot: "",
            block: "",
          } /*, { id: id2, plot: "", block: block }*/,
        ],
      },
    });

    dispatch({
      type: "calculator/set-block-and-plot",
      payload: {
        id: id,
        block: block,
        plot: plot,
      },
    });

    navigate(base + "?stage=2");
  };

  const results = items
    .filter((item) => item.block === `${block}`)
    .filter((item) => query !== '' && item.plot.includes(query))
    .map((item, index) => (
      <SearchWidget.Result key={index} {...item} onClick={selected} />
    ));

  return (
    <div className="">
      <div className="font-bold px-12 py-4">{"או"}</div>
      <div className="p-0 border border-black">
        <div className="border border-black flex flex-row gap-x-8 justify-between px-6 py-2">
          <div className="flex flex-row items-center gap-x-2.5">
            <span className="block font-bold">{"גוש"}</span>

            <select
              className="block p-1 border-grey"
              onChange={selectBlock}
              value={block}
            >
              <option>{''}</option>
              {options.map((op, index) => (
                <option key={index} value={op}>
                  {op}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-row items-center gap-x-2.5">
            <span className="block font-bold">{"הקלד/י מספר חלקה"}</span>
            <input
              type="text"
              className="border border-grey block p-1"
              onChange={search}
            />
          </div>
        </div>
                
        {results.length > 0 && <SearchWidget.Body items={results} />}
      </div>
    </div>
  );
};

SearchWidget.Header = ({ items }) => {}
SearchWidget.Body = ({ items }) => {

  return (
<div className="space-y-2 px-6 py-2">{items}</div>
  )
}


SearchWidget.Result = ({ name, block, plot, onClick }) => {
  return (
    <button
      value={`${name}-${block}-${plot}`}
      onClick={onClick}
      className="block"
    >
      {`גוש ${block} - חלקה ${plot} | ${name}`}
    </button>
  );
};

export default Stage1;
