import React from "react"
import { navigate, Link } from "gatsby"
import translations from "../../translations/he.json"
import ShortUniqueId from 'short-unique-id'
import { useSelector, useDispatch } from 'react-redux'
import CompoundUsageTable from "./compound-usage"

const uqid = new ShortUniqueId({ length: 10 })

const Stage2 = ({ base }) => {
  const state = useSelector(s => s.calculator)

  return (
    <>
      {state.compoundId && <Stage2Inner base={base} />}
      {!state.compoundId && <div><Link to="/הרובע-הפתוח/מחשבון-פלוס">{translations['Please select compound']}</Link></div>}
    </>
  )
}

const Stage2Inner = ({ base }) => {


  const state = useSelector(s => s.calculator)
  const dispatch = useDispatch()

  const compoundMapUrl = `/calculator-images/plots/${state.compoundId}.png`

  const keys = state.plots.map(e => `${e.block}-${e.plot}`)
  const options0 = state.compound.plots.map(e => ({ block: e.block, plot: e.plot }))
  const options = options0.filter(e => !keys.includes(`${e.block}-${e.plot}`))

  const items = state.plots.map((e, index, all) => {

    const options1 = [...options]
    if (e.block && e.plot) {
      options1.push({ block: e.block, plot: e.plot })
    }

    return {
      plot: { ...e },
      freeze: index + 1 < all.length,
      options: options1
    }
  })

  //console.log('ites', items)

  const submit = () => {
    navigate(base + '?stage=3')
    // add one usage input
    dispatch({
      type: 'calculator/add-usage',
      payload: { id: uqid(), yeod: '', label: '', area: 0, cost: 0, revenue: 0 }
    })
  }

  const add = () => {
    dispatch({
      type: 'calculator/add-plot-input',
      payload: { id: uqid(), plot: '', block: '' }
    })
  }


  const disabled = items.filter(p => p.plot.block && p.plot.plot).length === 0
  /*
      if (items.length === 0) {
        add()
      } */

  //console.log(items)
  return (
    <div className="flex flex-col-reverse lg:flex-row">
      <div className="lg:w-1/2">

        <div>

          <div className="text-2xl font-bold text-blue">{translations['Which plots are you interested in']}</div>

          <div>
            {items.map(p => (
            <InputBlockPlot key={p.plot.id} value={p.plot} freeze={p.freeze} options={p.options} />
          ))}
          </div>

        </div>

        <div className="my-6">
          <button onClick={add} className="w-8 h-8 bg-yellow text-white text-base font-bold rounded-full border flex justify-center  items-center" >+</button>
        </div>

        <div className="my-6">
          <button className={"border  text-white p-4 bg-navy " + (disabled ? 'opacity-50' : '')} onClick={submit} disabled={disabled}>{translations['Assembling a mix of uses is desirable']}</button>
        </div>

        <div className="space-y-4 py-20">
          <div className="text-2xl font-bold text-blue">{translations['possible uses']} {state.compoundId}</div>
          <div className="flex flex-row">
            <div className=""><CompoundUsageTable /></div>
          </div>
        </div>

      </div>

      <div className=" lg:w-1/2 mx-auto  text-center">
        <img src={compoundMapUrl} alt="plot" />
        
      </div>
    </div>
  )
}


const SelectPlot = ({ options, onSelect, value, freeze }) => {

  return (
    <select onChange={onSelect} value={value} className="p-2" disabled={freeze}>
      <option key="-" value="">-</option>
      {options.map((e, index) => (
        <option value={e} key={index}>{e}</option>
      ))}
    </select>
  )
}
const SelectBlock = ({ id, options, onChange, value, freeze }) => {
  //console.log(value)
  return (
    <select onChange={onChange} value={value} className="p-2" id={id} disabled={freeze}>
      <option key="select" value="">-</option>
      {options.map((e, index) => (
        <option value={e} key={index}  >{e}</option>
      )
      )}
    </select>
  )
}
const InputBlockPlot = ({ value, freeze, options }) => {

  //const state = useSelector(s => s.calculator)
  const dispatch = useDispatch()

  const id = value.id

  const onRemove = (e) => {
    dispatch({
      type: 'calculator/remove-block-plot-input',
      payload: { id: e.target.id }
    })
  }

  const onSelect = (e) => {
    console.log(e.target.value, id)
    dispatch({
      type: 'calculator/set-block',
      payload: {
        id: id,
        value: e.target.value
      }
    })
  }

  const onSelectPlot = (e) => {
    //console.log(e.target.value, id)
    dispatch({
      type: 'calculator/set-plot',
      payload: {
        id: id,
        value: e.target.value
      }
    })

  }

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const blocks = options.map(e => e.block).filter(onlyUnique)
  const plots = options.filter(e => e.block === value.block).map(e => e.plot)

  return (
    <div className="flex flex-row  items-center content-center p-4  space-x-8 space-x-reverse lg:w-3/4">

      <div className="flex  border border-black ">
        <div className="p-2">{translations['block']}:
          <SelectBlock options={blocks} onChange={onSelect} value={value.block} freeze={freeze} />
        </div>
        <div className="p-2">{translations['plot']}:
          <SelectPlot options={plots} onSelect={onSelectPlot} value={value.plot} freeze={freeze} /></div>
      </div>
      <button className="border rounded-full w-8 h-8 bg-[#ECB759] text-white flex justify-center  items-center" id={id} onClick={onRemove}>-</button>

    </div>
  )
}

export default Stage2
