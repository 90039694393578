import React from "react"
import translations from "../../translations/he.json"
import { useSelector } from 'react-redux'

const Plots = () => {

  const state = useSelector(s => s.calculator)

  const blocks = state.plots
    .filter(e => e.block && e.plot)
    .map(e => e.block)
  const unique = [...new Set(blocks)];

  const byBlock = unique.map(e => ({
    block: e,
    plots: state.plots.filter(p => p.block === e).map(p => p.plot),
  }))


  return (

    <div className="">

      {byBlock.map((m, index) => (
        <div className="border border-black p-3 my-3 -grid -gap-8 -grid-cols-2  flex flex-row justify-between lg:justify-start w-full lg:w-fit lg:space-x-12 lg:space-x-reverse" key={index}>
          <div><span className="font-bold">{translations['plot']}</span>: <span className="border border-black p-1 min-w-[105px]">{m.plots.join(', ')}</span></div>
          <div><span className="font-bold">{translations['block']}</span>: {m.block} </div>
        </div>
      ))}

    </div>

  )
}


export default Plots