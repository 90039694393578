const compunds_restrictions = [
  {
    compound: 'A',
    amount: 0.3,
    yeodim: [
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
      {
        "id": "150",
        "main": "1",
        "title": "דיור מיוחד (מבא\"ת)",
        "label": "דיור מיוחד"
      },
    ]
  },
  {
    compound: 'B',
    amount: 0.4,
    yeodim: [
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },]
  },
  {
    compound: 'C',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },

    ]
  },
  {
    compound: 'D',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
    ]
  },
  {
    compound: 'E',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
    ]
  },
  {
    compound: 'F',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
    ]
  },
  {
    compound: 'G',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
    ]
  },
  {
    compound: 'H',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
    ]
  },
  {
    compound: 'I',
    amount: 0.3,
    yeodim: [
      {
        "id": "210",
        "main": "1",
        "title": "מסחר (מבא\"ת)",
        "label": "מסחר"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
    ]
  },
  {
    compound: 'J',
    amount: 0.3,
    yeodim: [
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
    ]
  },
  {
    compound: 'K',
    amount: 0.4,
    yeodim: [
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
      {
        "id": "150",
        "main": "1",
        "title": "דיור מיוחד (מבא\"ת)",
        "label": "דיור מיוחד"
      },
    ]
  },
  {
    compound: 'L',
    amount: 0.4,
    yeodim: []
  },
  {
    compound: 'M',
    amount: 0.35,
    yeodim: [
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
      {
        "id": "150",
        "main": "1",
        "title": "דיור מיוחד (מבא\"ת)",
        "label": "דיור מיוחד"
      },
    ]
  },
  {
    compound: 'N',
    amount: 0.3,
    yeodim: [
      {
        "id": "10",
        "main": "1",
        "title": "מגורים (מבא\"ת)",
        "label": "מגורים"
      },
      {
        "id": "600",
        "main": "1",
        "title": "תיירות (מבא\"ת)",
        "label": "תיירות"
      },
      {
        "id": "150",
        "main": "1",
        "title": "דיור מיוחד (מבא\"ת)",
        "label": "דיור מיוחד"
      },
    ]
  }
]

export { compunds_restrictions };