import React, { useRef } from "react"
import translations from "../../translations/he.json"
import Plots from "./plots"
import Usages from "./usage"
import Profit from "./profit"
import { useSelector, useDispatch } from 'react-redux'
//import PdfDocument, { PdfFooter, MailtoBlockPdf } from "../../components/calculator/pdf-content"
import Icon from "../../images/downalod.svg"
import { navigate, Link } from "gatsby"

const Stage4 = ({ base }) => {

  const state = useSelector(s => s.calculator)

  return (
    <>
      {state.compoundId && <Stage4Inner base={base} />}
      {!state.compoundId && <div><Link to="/הרובע-הפתוח/מחשבון-פלוס">{translations['Please select compound']}</Link></div>}
    </>
  )
}

const Stage4Inner = ({ base }) => {

  const ref = useRef(null);
  const ref4Page2 = useRef(null);
  const ref4Footer = useRef(null);

  const dispatch = useDispatch()

  const state = useSelector(s => s.calculator)
  //const user = useSelector(s => s.user)
  const pdf = useSelector(s => s.pdf)

  const summary = state.summary

  const compoundMapUrl = `/calculator-images/plots/${state.compoundId}.png`

  return (
    <div className="flex flex-col-reverse lg:flex-row">

      <div className="lg:w-1/2 space-y-8">

        <div>
          <div className="text-2xl text-blue font-bold">{translations['selected plots']}</div>
          <Plots />
        </div>


        <div className="">
          <span className="text-2xl font-bold text-blue">{translations['area']}</span>{"  "} {summary.area_plots.toLocaleString()} {translations['Square meter']}
        </div>

        <div className="">
          <div className="text-2xl text-blue font-bold">{translations['selected uses']}</div>
          <Usages />
        </div>

        <div className="">
          <div className="text-2xl text-blue font-bold">{translations['Construction rights and general consideration']}</div>
          <Profit />
          <div className="border-yellow border-t-2 my-12 h-1"></div>
        </div>

        <div>


          <div className="flex flex-row justify-between mt-24">

            <button
              className="py-2 bg-navy text-white hover:bg-blue font-bold px-6"
              onClick={() => {
                
                //console.log('state' , state)

                dispatch({
                  type: 'pdf/data', payload: {
                    compoundId: state.compoundId,
                    compound: state.compound,
                    plots: state.plots,
                    usage: state.usage,
                    open: state.open,
                    summary: state.summary,
                  }
                })

                dispatch({
                  type: 'pdf/showModal1', payload: {
                    ref: ref,
                    ref4Footer: ref4Footer,
                    ref4Page2: ref4Page2
                  }
                })
              }}
            >
              הורדת סיכום נתוני התכנית
              <img src={Icon} alt="downalod" className="inline px-2" />
            </button>
            {/* </Pdf2String> */}


            <button
              className="py-2 bg-[#9C9C9C] text-white hover:bg-blue font-bold px-6"
              onClick={() => { navigate('/הרובע-הפתוח/מחשבון-פלוס') }}
            >
              חזרה
            </button>
          </div>

          <ModalHidden show={pdf.showModal1} close={() => { dispatch({ type: 'pdf/hideModal1', payload: {} }) }} >
          <div className=" h-full flex flex-row items-center content-center justify-center">
              <div className="text-center">

                <div className="text-3xl text-blue font-bold my-4">מכין קובץ</div>
                <button>
                  <svg className=" animate-spin -ml-1 mr-3 h-5 w-5 text-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </ModalHidden>


        </div>
        <div></div>
      </div>
      <div className="lg:w-1/2">
        <img src={compoundMapUrl} alt="plot" />
      </div>
    </div>
  )
}


const ModalHidden = ({ show, close, children }) => {

  return (


    <div className={"modal opacity-0  fixed w-full h-full top-0  left-0 flex items-center justify-center " + (show ? ' block opacity-100' : ' hidden opacity-0')}>
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50" onClick={() => close(false)} onKeyDown={() => close(false)} role="presentation" ></div>

      <div className="-modal-container bg-white w-11/12 h-2/3 lg:max-w-[840px] lg:max-h-[500px] mx-auto rounded overflow-y-scroll" style={{ zIndex: '150' }}>
        <div className="modal-content py-4 px-6 " >

          <div className="flex justify-between items-center ">
            <button className="modal-close cursor-pointer " onClick={() => close(false)}>
              <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </button>
          </div>

          {children}

        </div>
      </div>
    </div>

  )
}


// const Modal = ({ close, children }) => {


//   return (


//     <div className={"modal opacity-0  fixed w-full h-full top-0  left-0 flex items-center justify-center  block opacity-100"}>
//       <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50" onClick={() => close(false)} onKeyDown={() => close(false)} role="presentation" ></div>

//       <div className="-modal-container bg-white w-11/12 h-2/3 lg:max-w-[400px] lg:max-h-[240px] mx-auto rounded " style={{ zIndex: '150' }}>
//         <div className="modal-content py-4 px-6 h-full" >

//           {/* <div className="flex justify-between items-center ">
//             <button className="modal-close cursor-pointer " onClick={() => close(false)}>
//               <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
//                 <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
//               </svg>
//             </button>
//           </div> */}

//           {children}

//         </div>
//       </div>
//     </div>

//   )
// }


export default Stage4
